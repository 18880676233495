import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import './index.css';

interface Todo {
  id: number;
  name: string;
  priority: number;
  isDone: boolean;
  deadline: Date;
}

export const taskEndDate = ({ deadline, isDone, priority, name }: Todo): string => {
  dayjs.locale(ja);
  const now = dayjs();
  const end = dayjs(deadline);
  const diffHours = now.diff(end, 'hour');
  const stars = '★'.repeat(priority);

  return `
    【${isDone ? "済" : "未"}】
    ${name} ${stars}
    （${now < end ? 
        `期限まで残り${Math.abs(diffHours)}時間` : 
        `期限を${diffHours}時間超過`}）
  `;
};

const TodoApp: React.FC = () => {
  const [todos, setTodos] = useState<Todo[]>([]);
  const [newTodo, setNewTodo] = useState<string>('');
  const [newPriority, setNewPriority] = useState<number>(1);
  const [newDeadline, setNewDeadline] = useState<Date>(new Date());

  const addTodo = () => {
    if (newTodo.trim() === '') return;
    const newTodoItem: Todo = {
        id: todos.length + 1, 
        name: newTodo,
        priority: newPriority,
        isDone: false,
        deadline: newDeadline,
    };
    setTodos([...todos, newTodoItem]);
    setNewTodo('');
  };

  const toggleTodo = (id: number) => {
    setTodos(
      todos.map(todo =>
        todo.id === id ? { ...todo, isDone: !todo.isDone } : todo
      )
    );
  };

  return (
    <div>
      <h1>Todo List</h1>
      題名<input
        type="text"
        value={newTodo}
        onChange={(e) => setNewTodo(e.target.value)}
      />
      <br />
      優先度<input
        type="number"
        value={newPriority}
        min={1}
        onChange={(e) => setNewPriority(parseInt(e.target.value))}
      />
      <br />
      <input
        type="datetime-local"
        value={newDeadline.toISOString().split('T')[0]}
        onChange={(e) => setNewDeadline(new Date(e.target.value))}
      />
      <button id="addTodo" onClick={addTodo}>Add Todo</button>
      <ul>
        {todos.map(todo => (
            <li key={todo.id} style={{ 
            textDecoration: todo.isDone ? 'line-through' : 'none',
            fontWeight: todo.priority >= 3 ? 'bold' : 'normal',
            color: todo.priority >= 4 ? 'red' : 'black',
            fontSize: todo.priority >= 5 ? '1.2em' : '1em'
            }}>
            {taskEndDate(todo)}
            <button onClick={() => toggleTodo(todo.id)}>
              {todo.isDone ? 'Undo' : 'Complete'}
            </button>
            </li>
        ))}
      </ul>
    </div>
  );
}

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <TodoApp />
    </React.StrictMode>
  );
}